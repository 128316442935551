import service from './service'

const getAllMaintenances = async ({ commit }) => {
  const data = await (await service.getAllMaintenances()).data

  console.log('result', data)

  await commit('SAVE_ALL_MAINTENANCES', data)
}

const saveMaintenance = async ({ commit }, obj: Record<string, string>) => {
  const data = await (await service.saveMaintenance(obj)).data

  console.log('result', data)

  await commit('SAVE_MAINTENANCE', data)
}

export default {
  getAllMaintenances,
  saveMaintenance,
}
