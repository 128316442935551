import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllFinancialReports = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/financial-reports',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const getAllReportServicesByCustomerId = async (customerId) => {
  return axios.request({
    method: 'GET',
    url: `/api/financial-reports/${customerId}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllFinancialReports,
  getAllReportServicesByCustomerId
}
