import service from './service'

const getAllExpenses = async ({ commit }) => {
  const data = await (await service.getAllExpenses()).data

  await commit('SAVE_ALL_EXPENSES', data)
}

export default {
  getAllExpenses,
}