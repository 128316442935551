import * as types from './mutation-types'
export default {
  [types.SAVE_ALL_ROLES](state, data) {
    console.log('mutation ', data)
    state.allRoles = data
  },
  [types.SAVE_ROLE](state, data) {
    console.log('mutation ', data)
    state.allRoles.push(data)
  },
}
