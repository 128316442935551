import * as types from './mutation-types'
export default {
  [types.SAVE_ALL_VEHICLE_USAGE_INFOS](state, data) {
    console.log('mutation ', data)
    state.allVehicleUsageInfos = data
  },
  [types.SAVE_VEHICLE_USAGE_INFO](state, data) {
    console.log('mutation ', data)
    state.vehicleUsageInfo = data
  },
}
