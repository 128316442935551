import service from './service'

const getAllCostCenters = async ({ commit }) => {
  const data = await (await service.getAllCostCenters()).data

  await commit('SAVE_ALL_COST_CENTERS', data)
}

export default {
  getAllCostCenters,
}
