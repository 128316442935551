import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllVehicleMovementRecords = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/vehicle-movement-records',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveVehicleMovementRecord = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/vehicle-movement-records',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateVehicleMovementRecord = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/vehicle-movement-records/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteVehicleMovementRecord = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/vehicle-movement-records/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllVehicleMovementRecords,
  saveVehicleMovementRecord,
  deleteVehicleMovementRecord,
  updateVehicleMovementRecord,
}
