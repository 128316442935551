import service from './service'

const getAllPassengers = async ({ commit }) => {
  const data = await (await service.getAllPassengers()).data.data

  await commit('SAVE_ALL_PASSENGERS', data)
}

export default {
  getAllPassengers,
}
