import service from './service'

const getAllHolidays = async ({ commit }) => {
  const data = await (await service.getAllHolidays()).data

  await commit('SAVE_ALL_HOLIDAYS', data)
}

export default {
  getAllHolidays,
}
