import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllInvoices = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/invoices',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const updateInvoice = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/invoices/${id}`,
    data: { id: id, ...data },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const saveInvoice = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/invoices',
    data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const deleteInvoice = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/invoices/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export default {
  getAllInvoices,
  updateInvoice,
  deleteInvoice,
  saveInvoice,
}
