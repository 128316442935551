import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllMaintenanceSchedulings = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/maintenance-schedulings',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const updateMaintenanceScheduling = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/maintenance-schedulings/${id}`,
    data: { id: id, ...data },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const saveMaintenanceScheduling = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/maintenance-schedulings',
    data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const deleteMaintenanceScheduling = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/maintenance-schedulings/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export default {
  getAllMaintenanceSchedulings,
  updateMaintenanceScheduling,
  deleteMaintenanceScheduling,
  saveMaintenanceScheduling,
}
