import service from './service'

const getAllPermissions = async ({ commit }) => {
  const data = await (await service.getAllPermissions()).data

  console.log('result', data)

  await commit('SAVE_ALL_PERMISSIONS', data)
}

const savePermission = async ({ commit }, obj: Record<string, string>) => {
  const data = await (await service.savePermission(obj)).data

  console.log('result', data)

  await commit('SAVE_PERMISSION', data)
}

export default {
  getAllPermissions,
  savePermission,
}
