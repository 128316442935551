import service from './service'

const getAllSuppliers = async ({ commit }) => {
  const data = await (await service.getAllSuppliers()).data

  console.log('result', data)

  await commit('SAVE_ALL_SUPPLIERS', data)
}

const saveSupplier = async ({ commit }, obj: Record<string, string>) => {
  return service.saveSupplier(obj).then(async result => {
    await commit('SAVE_SUPPLIER', result.data)
  })
}

export default {
  getAllSuppliers,
  saveSupplier,
}
