import * as types from './mutation-types'
export default {
  [types.SAVE_ALL_MAINTENANCE_SCHEDULINGS](state, data) {
    console.log('mutation ', data)
    state.allMaintenanceSchedulings = data
  },
  [types.SAVE_MAINTENANCE_SCHEDULING](state, data) {
    console.log('mutation ', data)
    state.maintenanceScheduling = data
  },
}
