import service from './service'

const getAllSharedServicePassengers = async ({ commit }) => {
  const data = await (await service.getAllSharedServicePassengers()).data

  await commit('SAVE_ALL_SHARED_SERVICE_PASSENGERS', data)
}

export default {
  getAllSharedServicePassengers,
}