import * as types from './mutation-types'
export default {
  [types.SAVE_ALL_IDENTIFIED_PROBLEMS](state, data) {
    console.log('mutation ', data)
    state.allIdentifiedProblems = data
  },
  [types.SAVE_IDENTIFIED_PROBLEM](state, data) {
    console.log('mutation ', data)
    state.identifiedProblem = data
  },
}
