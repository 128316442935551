import service from './service'

const getAllInvoices = async ({ commit }) => {
  const data = await (await service.getAllInvoices()).data

  console.log('result', data)

  await commit('SAVE_ALL_INVOICES', data)
}

const saveInvoice = async ({ commit }, obj: Record<string, string>) => {
  const data = await (await service.saveInvoice(obj)).data

  console.log('result', data)

  await commit('SAVE_INVOICE', data)
}

export default {
  getAllInvoices,
  saveInvoice,
}
