import service from './service'

const getAllPartStocks = async ({ commit }) => {
  const data = await (await service.getAllPartStocks()).data

  await commit('SAVE_ALL_PART_STOCKS', data)
}

export default {
  getAllPartStocks,
}
