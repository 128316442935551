import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllTaxes = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/taxes',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveTax = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/taxes',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateTax = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/taxes/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteTax = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/taxes/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllTaxes,
  saveTax,
  deleteTax,
  updateTax,
}
