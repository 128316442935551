import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllBankBalances = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/bank-balances',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveBankBalance = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/bank-balances',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateBankBalance = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/bank-balances/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteBankBalance = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/bank-balances/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllBankBalances,
  saveBankBalance,
  deleteBankBalance,
  updateBankBalance,
}
