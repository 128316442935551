import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllMaintenances = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/maintenances',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const updateMaintenance = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/maintenances/${id}`,
    data: { id: id, ...data },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const saveMaintenance = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/maintenances',
    data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const deleteMaintenance = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/maintenances/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export default {
  getAllMaintenances,
  updateMaintenance,
  deleteMaintenance,
  saveMaintenance,
}
