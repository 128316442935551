import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'Access-control-allow-headers': 'Authorization',
  },
})

const getAllAdditionalUsers = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/additional-users',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveAdditionalUser = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/additional-users',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateAdditionalUser = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/additional-users/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteAdditionalUser = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/additional-users/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllAdditionalUsers,
  saveAdditionalUser,
  deleteAdditionalUser,
  updateAdditionalUser,
}
