
<template>
  <div id="app" class="h-full w-full">
    <div v-if="!$route.meta.public">
      <sidebar-component></sidebar-component>
      <div class="relative h-full" v-bind:class="{
        'md:ml-64':
          $route.name.toLowerCase() !== 'services' &&
          $route.name.toLowerCase() !== 'logistics' && !isToggleMenuActive,
        'margin-toggle-menu': isToggleMenuActive,
      }">
        <navbar-component></navbar-component>
        <div class="relative bg-gray-800" v-bind:class="{
          'pb-32':
            $route.name.toLowerCase() !== 'services' &&
            $route.name.toLowerCase() !== 'logistics',
          'pt-12':
            $route.name.toLowerCase() !== 'services' &&
            $route.name.toLowerCase() !== 'logistics',
          'pt-32':
            $route.name.toLowerCase() === 'services' ||
            $route.name.toLowerCase() === 'logistics',
        }">
          <div class="px-4 md:px-10 mx-auto w-full"></div>
        </div>
        <div class="px-4 py-10 md:px-10 mx-auto w-full bg-gray-200 -m-32 z-10">
          <div class="pb-10">
            <router-view />
          </div>
          <footer v-if="!$route.meta.public &&
            $route.name.toLowerCase() !== 'services' &&
            $route.name.toLowerCase() !== 'logistics'
            " :class="collapse ? 'hidden' : 'block'" class="bg-gray-200 bottom-0 z-10 fixed w-full text-left p-4">
            © Onix Autolocadora 2020. Todos os direitos reservados.
            <br />
          </footer>
        </div>
      </div>
    </div>
    <div v-if="$route.meta.public">
      <router-view />
    </div>
  </div>
</template>

<script>

import NavbarComponent from '@/components/Navbar.vue'
import SidebarComponent from '@/components/Sidebar.vue'
export default {
  components: {
    NavbarComponent,
    SidebarComponent,
  },
  data() {
    return {
      date: new Date().getFullYear(),
      isToggleMenuActive: false,
    }
  },
  mounted() {
    this.$root.$on('collapse-menu', (value) => {
      this.isToggleMenuActive = value
    })
  },
}
</script>

<!-- <style lang="scss">
html {
  background-color: #edf2f7 !important;
}

.margin-toggle-menu {
  margin-left: 50px;
}
</style> -->