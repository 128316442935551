import service from './service'

const getAllRequesters = async ({ commit }) => {
  const data = await (await service.getAllRequesters()).data

  await commit('SAVE_ALL_REQUESTER', data)
}

export default {
  getAllRequesters,
}
