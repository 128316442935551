import Vue from 'vue'
import Vuex from 'vuex'

import auth from '../features/auth/store/index'
import users from '../features/users/store/index'
import roles from '../features/roles/store/index'
import suppliers from '../features/suppliers/store/index'
import vehicles from '../features/vehicles/store/index'
import permissions from '../features/permissions/store/index'
import vehicleTypes from '../features/vehicle-types/store/index'
import vehicleModels from '../features/vehicle-models/store/index'
import costCenters from '../features/cost-centers/store/index'
import accessories from '../features/accessories/store/index'
import units from '../features/units/store/index'
import readjustmentIndices from '../features/readjustment-indices/store/index'
import noteTypes from '../features/note-types/store/index'
import invoices from '../features/invoices/store/index'
import identifiedProblems from '../features/identified-problems/store/index'
import authorizedServices from '../features/authorized-services/store/index'
import maintenances from '../features/maintenances/store/index'
import maintenanceStatus from '../features/maintenance-status/store/index'
import maintenanceTypes from '../features/maintenance-types/store/index'
import vehicleGroups from '../features/vehicle-groups/store/index'
import additionalUsers from '../features/additional-users/store/index'
import vehicleMovementRecords from '../features/vehicle-movement-records/store/index'
import reviewCriterias from '../features/review-criterias/store/index'
import additionalInformations from '../features/additional-informations/store/index'
import maintenanceSchedulings from '../features/maintenance-schedulings/store/index'
import employees from '../features/employees/store/index'
import vehicleUsageInfos from '../features/vehicle-usage-infos/store/index'
import dashboards from '../features/dashboards/store/index'
import appraisals from '../features/appraisals/store/index'
import appraisalTypes from '../features/appraisal-types/store/index'
import locations from '../features/locations/store/index'
import customers from '../features/customers/store/index'
import itineraries from '../features/itineraries/store/index'
import drivers from '../features/drivers/store/index'
import vehicleColors from '../features/vehicle-colors/store/index'
import passengers from '../features/passengers/store/index'
import services from '../features/services/store/index'
import taxes from '../features/taxes/store/index'
import fuelSupplies from '../features/fuel-supplies/store/index'
import bills from '../features/bills/store/index'
import partStocks from '../features/part-stocks/store/index'
import infractions from '../features/infractions/store/index'
import boardingPoints from '../features/boarding-points/store/index'
import boardingLines from '../features/boarding-lines/store/index'
import expenses from '../features/expenses/store/index'
import recipes from '../features/recipes/store/index'
import notifications from '../features/notifications/store/index'
import requesters from '../features/requesters/store/index'
import pendingReleases from '../features/pending-releases/store/index'
import serviceOccurrences from '../features/service-occurrences/store/index'
import serviceObservations from '../features/service-observations/store/index'
import financialCategories from '../features/financial-categories/store/index'
import financialSubCategories from '../features/financial-subcategories/store/index'
import financialReports from '../features/financial-reports/store/index'
import financialRecurrences from '../features/financial-recurrences/store/index'
import banks from '../features/banks/store/index'
import bankBalances from '../features/bank-balances/store/index'
import sharedServicePassengers from '../features/shared-service-passengers/store/index'
import audits from '../features/audits/store/index'
import holidays from '../features/holidays/store/index'
import timeIntervals from '../features/time-intervals/store/index'
import tolls from '../features/tolls/store/index'

Vue.use(Vuex)

const modules = {
  auth,
  users,
  roles,
  suppliers,
  vehicles,
  permissions,
  vehicleTypes,
  vehicleModels,
  costCenters,
  accessories,
  units,
  readjustmentIndices,
  noteTypes,
  invoices,
  identifiedProblems,
  authorizedServices,
  maintenances,
  maintenanceTypes,
  maintenanceStatus,
  vehicleGroups,
  additionalUsers,
  vehicleMovementRecords,
  reviewCriterias,
  additionalInformations,
  maintenanceSchedulings,
  employees,
  vehicleUsageInfos,
  dashboards,
  appraisals,
  appraisalTypes,
  locations,
  customers,
  itineraries,
  drivers,
  vehicleColors,
  passengers,
  services,
  taxes,
  fuelSupplies,
  bills,
  partStocks,
  infractions,
  boardingPoints,
  boardingLines,
  expenses,
  recipes,
  notifications,
  requesters,
  pendingReleases,
  serviceOccurrences,
  serviceObservations,
  financialCategories,
  financialSubCategories,
  financialReports,
  financialRecurrences,
  audits,
  banks,
  bankBalances,
  sharedServicePassengers,
  holidays,
  timeIntervals,
  tolls
}

export default new Vuex.Store({
  modules,
})
