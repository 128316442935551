import service from './service'

const getAllFinancialSubCategories = async ({ commit }) => {
  const data = await (await service.getAllFinancialSubCategories()).data

  await commit('SAVE_ALL_FINANCIAL_SUBCATEGORIES', data)
}

export default {
  getAllFinancialSubCategories,
}
