import service from './service'

const getAllAccessories = async ({ commit }) => {
  const data = await (await service.getAllAccessories()).data

  console.log('result', data)

  await commit('SAVE_ALL_ACCESSORIES', data)
}

const saveaccessory = async ({ commit }, obj: Record<string, string>) => {
  const data = await (await service.saveaccessory(obj)).data

  console.log('result', data)

  await commit('SAVE_ACCESSORY', data)
}

export default {
  getAllAccessories,
  saveaccessory,
}
