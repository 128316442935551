import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllAuthorizedServices = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/authorized-services',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const updateAuthorizedService = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/authorized-services/${id}`,
    data: { id: id, ...data },
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const saveAuthorizedService = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/authorized-services',
    data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

const deleteAuthorizedService = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/authorized-services/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

export default {
  getAllAuthorizedServices,
  updateAuthorizedService,
  deleteAuthorizedService,
  saveAuthorizedService,
}
