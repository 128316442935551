import service from './service'

const getAllFinancialReports = async ({ commit }) => {
  const data = await (await service.getAllFinancialReports()).data

  await commit('SAVE_ALL_FINANCIAL_REPORTS', data)
}

export default {
  getAllFinancialReports,
}
