import service from './service'

const getAllAdditionalUsers = async ({ commit }) => {
  const data = await (await service.getAllAdditionalUsers()).data

  console.log('result', data)

  await commit('SAVE_ALL_ADDITIONAL_USERS', data)
}

const saveAdditionalUser = async ({ commit }, obj: Record<string, string>) => {
  return service.saveAdditionalUser(obj).then(async result => {
    await commit('SAVE_ADDITIONAL_USER', result.data)
  })
}

export default {
  getAllAdditionalUsers,
  saveAdditionalUser,
}
