import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllItineraries = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/itineraries',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveItinerary = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/itineraries',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateItinerary = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/itineraries/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteItinerary = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/itineraries/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllItineraries,
  saveItinerary,
  deleteItinerary,
  updateItinerary,
}
