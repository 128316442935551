import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllPendingReleases = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/pending-releases',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const savePendingRelease = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/pending-releases',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updatePendingRelease = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/pending-releases/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deletePendingRelease = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/pending-releases/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllPendingReleases,
  savePendingRelease,
  deletePendingRelease,
  updatePendingRelease,
}
