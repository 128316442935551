import service from './service'

const getAllFinancialRecurrences = async ({ commit }) => {
  const data = await (await service.getAllFinancialRecurrences()).data

  await commit('SAVE_ALL_FINANCIAL_RECURRENCES', data)
}

export default {
  getAllFinancialRecurrences,
}