import service from './service'

const getAllNotifications = async ({ commit }) => {
  const data = await (await service.getAllNotifications()).data

  await commit('SAVE_ALL_NOTIFICATIONS', data)
}

export default {
  getAllNotifications,
}