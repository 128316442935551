import service from './service'

const getAllVehicleModels = async ({ commit }) => {
  const data = await (await service.getAllVehicleModels()).data

  console.log('result', data)

  await commit('SAVE_ALL_VEHICLE_MODELS', data)
}

const saveVehicleModel = async ({ commit }, obj: Record<string, string>) => {
  return service.saveVehicleModel(obj).then(async result => {
    await commit('SAVE_VEHICLE_MODEL', result.data)
  })
}

export default {
  getAllVehicleModels,
  saveVehicleModel,
}
