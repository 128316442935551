import service from './service'

const getAllMaintenanceSchedulings = async ({ commit }) => {
  const data = await (await service.getAllMaintenanceSchedulings()).data

  console.log('result', data)

  await commit('SAVE_ALL_MAINTENANCE_SCHEDULINGS', data)
}

const saveMaintenanceScheduling = async (
  { commit },
  obj: Record<string, string>
) => {
  const data = await (await service.saveMaintenanceScheduling(obj)).data

  console.log('result', data)

  await commit('SAVE_MAINTENANCE_SCHEDULING', data)
}

export default {
  getAllMaintenanceSchedulings,
  saveMaintenanceScheduling,
}
