import service from './service'

const getAllFinancialCategories = async ({ commit }) => {
  const data = await (await service.getAllFinancialCategories()).data

  await commit('SAVE_ALL_FINANCIAL_CATEGORIES', data)
}

export default {
  getAllFinancialCategories,
}
