import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllReviewCriterias = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/review-criterias',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

// const saveaccessory = async (data: Record<string, string>) => {
//   return axios.request({
//     method: 'POST',
//     url: '/api/review-criterias',
//     data,
//     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
//   })
// }

// const updateaccessory = async ({ id, ...data }) => {
//   return axios.request({
//     method: 'patch',
//     url: `/api/review-criterias/${id}`,
//     data: { id: id, ...data },
//     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
//   })
// }

// const deleteaccessory = async id => {
//   return axios.request({
//     method: 'DELETE',
//     url: `/api/review-criterias/${id}`,
//     headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
//   })
// }

export default {
  getAllReviewCriterias,
}
