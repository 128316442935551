import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllVehicleUsageInfos = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/vehicle-usage-infos',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveVehicleUsageInfo = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/vehicle-usage-infos',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllVehicleUsageInfos,
  saveVehicleUsageInfo,
}
