import service from './service'

const getAllBills = async ({ commit }) => {
  const data = await (await service.getAllBills()).data

  await commit('SAVE_ALL_BILLS', data)
}

export default {
  getAllBills,
}
