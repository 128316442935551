import service from './service'

const getAllAppraisalTypes = async ({ commit }) => {
  const data = await (await service.getAllAppraisalTypes()).data

  console.log('result', data)

  await commit('SAVE_ALL_APPRAISAL_TYPES', data)
}

export default {
  getAllAppraisalTypes,
}
