// export const MENU_ITEMS = [
//   {
//     name: 'Centros de Custos',
//     router: 'cost-centers',
//     type: 'cost-centers',
//   },
//   {
//     name: 'Notas Fiscais',
//     router: 'invoices',
//     type: 'invoices',
//   },
//   {
//     name: 'Manutenções',
//     type: 'maintenances',
//     children: [
//       {
//         name: 'Manutenções',
//         router: 'maintenances',
//         type: 'maintenances',
//       },
//       {
//         name: 'Agendamentos',
//         router: 'maintenance-schedulings',
//         type: 'maintenance-schedulings',
//       },
//       {
//         name: 'Informações Adicionais',
//         router: 'additional-informations',
//         type: 'additional-informations',
//       },
//       {
//         name: 'Problemas Identificados',
//         router: 'identified-problems',
//         type: 'identified-problems',
//       },
//       {
//         name: 'Serviços',
//         router: 'authorized-services',
//         type: 'authorized-services',
//       },
//     ],
//   },
//   {
//     name: 'Fornecedores',
//     router: 'suppliers',
//     type: 'suppliers',
//   },
//   {
//     name: 'Abastecimento',
//     router: 'fuel-supplies',
//     type: 'suppliers',
//   },
//   {
//     name: 'Veículos',
//     type: [
//       'vehicles',
//       'vehicle-movement-records',
//       'vehicle-groups',
//       'equipments',
//       'readjustment-indices',
//       'vehicle-models',
//       'federative-units',
//       'vehicle-types',
//     ],
//     children: [
//       {
//         name: 'Veículos',
//         router: 'vehicles',
//         type: 'vehicles',
//       },
//       {
//         name: 'Agrupamento de Veículos',
//         router: 'vehicle-groups',
//         type: 'vehicle-groups',
//       },
//       {
//         name: 'Equipamentos',
//         router: 'equipments',
//         type: 'equipments',
//       },
//       {
//         name: 'Acessorios',
//         router: 'accessories',
//         type: 'accessories',
//       },
//       {
//         name: 'Índices de Reajuste',
//         router: 'readjustment-indices',
//         type: 'readjustment-indices',
//       },
//       {
//         name: 'Tipos de veículos',
//         router: 'vehicle-types',
//         type: 'vehicle-types',
//       },
//       {
//         name: 'Modelos de veículos',
//         router: 'vehicle-models',
//         type: 'vehicle-models',
//       },
//     ],
//   },
//   { name: 'Clientes', type: 'customers', router: 'customers' },
//   { name: 'Motoristas', type: 'drivers', router: 'drivers' },
//   {
//     name: 'Serviços',
//     type: 'services',
//     children: [
//       {
//         name: 'Serviços',
//         router: 'services',
//         type: 'services',
//       },
//       {
//         name: 'Itnerários',
//         router: 'itineraries',
//         type: 'itineraries',
//       },
//       {
//         name: 'Locais',
//         router: 'locations',
//         type: 'locations',
//       },
//     ]
//   },
//   {
//     name: 'Usuários',
//     type: 'user',
//     children: [
//       {
//         name: 'Usuários',
//         router: 'users',
//         type: 'user',
//       },
//       {
//         name: 'Usuários Adicionais',
//         router: 'additional-users',
//         type: 'additional-users',
//       },
//       {
//         name: 'Cargos',
//         router: 'roles',
//         type: 'roles',
//       },
//       {
//         name: 'Permissões',
//         router: 'permissions',
//         type: 'permissions',
//       },
//     ],
//   },
// ]


export const MENU_ITEMS = [
  {
    name: 'Serviços',
    type: 'service',
    icon: 'arrow-up-bold-box-outline',
    children: [
      {
        name: 'Lançamento de serviços',
        router: 'services',
        type: 'services',
      },
      {
        name: 'Intervalos',
        router: 'time-intervals',
        type: 'time-intervals',
      },
      {
        name: 'Pedágios',
        router: 'tolls',
        type: 'tolls',
      },
      {
        name: 'Locais',
        router: 'locations',
        type: 'locations',
      },
      {
        name: 'Feriados',
        router: 'holidays',
        type: 'holidays',
      },
      {
        name: 'Pontos',
        router: 'boarding-points',
        type: 'boarding-points',
      },
      {
        name: 'Linhas',
        router: 'boarding-lines',
        type: 'boarding-lines',
      },
      {
        name: 'Taxas de viagem',
        router: 'taxes',
        type: 'taxes',
      },
    ]
  },
  {
    name: 'Cliente',
    type: 'customer',
    icon: 'account-arrow-right-outline',
    children: [
      { name: 'Clientes', type: 'customers', router: 'customers' },
      {
        name: 'Solicitantes',
        router: 'requesters',
        type: 'requesters',
      },
      { name: 'Passageiros', type: 'passengers', router: 'passengers' },
    ],
  },
  {
    name: 'Manutenção',
    type: 'maintenance',
    icon: 'hammer-screwdriver',
    children: [
      {
        name: 'Estoque de Peças',
        router: 'part-stocks',
        type: 'part-stocks',
      },
      {
        name: 'Serviços Autorizados',
        router: 'authorized-services',
        type: 'authorized-services',
      },
      {
        name: 'Manutenções',
        type: 'maintenances',
        router: 'maintenances',
      },
      {
        name: 'Agendamentos',
        router: 'maintenance-schedulings',
        type: 'maintenance-schedulings',
      },
      // {
      //   name: 'Informações Adicionais',
      //   router: 'additional-informations',
      //   type: 'additional-informations',
      // },
    ]
  },
  {
    name: 'Veículos',
    type: 'vehicles',
    icon: 'car-outline',
    children: [
      {
        name: 'Acessorios',
        router: 'accessories',
        type: 'accessories',
      },
      {
        name: 'Abastecimento',
        router: 'fuel-supplies',
        type: 'suppliers',
      },
      {
        name: 'Veículos',
        type: 'vehicles',
        router: 'vehicles',
      },
      {
        name: 'Multas',
        type: 'infractions',
        router: 'infractions',
      },
      {
        name: 'Cores de Veículos',
        type: 'vehicles',
        router: 'vehicle-colors',
      },
      {
        name: 'Tipos de veículos',
        router: 'vehicle-types',
        type: 'vehicle-types',
      },
      {
        name: 'Modelos de veículos',
        router: 'vehicle-models',
        type: 'vehicle-models',
      },
    ],
  },
  {
    name: 'Financeiro',
    type: 'financial',
    icon: 'cash',
    children: [
      {
        name: 'Gráficos',
        router: 'financial-charts',
        type: 'financial-charts',
      },
      {
        name: 'Relatórios',
        router: 'financial-reports',
        type: 'financial-reports',
      },
      {
        name: 'Contas a pagar',
        router: 'bills',
        type: 'bills',
      },
      {
        name: 'Bancos',
        router: 'banks',
        type: 'banks',
      },
      {
        name: 'Saldos bancários',
        router: 'bank-balances',
        type: 'bank-balances',
      },
      {
        name: 'Faturamento',
        type: 'financial-revenues',
        router: 'financial-revenues',
      },
      {
        name: 'Receitas',
        type: 'recipes',
        router: 'recipes',
      },
      {
        name: 'Despesas',
        router: 'expenses',
        type: 'expenses',
      },
      {
        name: 'Notas Fiscais',
        router: 'invoices',
        type: 'invoices',
      },
      {
        name: 'Recorrência',
        type: 'financial-recurrences',
        router: 'financial-recurrences',
      },
      {
        name: 'Centros de Custos',
        router: 'cost-centers',
        type: 'cost-centers',
      },
      {
        name: 'Categorias',
        router: 'financial-categories',
        type: 'financial-categories',
      },
      {
        name: 'Sub-categorias',
        router: 'financial-subcategories',
        type: 'financial-subcategories',
      },
    ],
  },
  {
    name: 'Fornecedores',
    router: '/suppliers',
    type: 'suppliers',
    icon: 'asterisk'
  },
  {
    name: 'Motoristas',
    icon: 'card-account-details-outline',
    type: 'drivers', router: 'drivers'
  },
  {
    name: 'Usuários',
    type: 'user',
    router: 'users',
    icon: 'account-multiple'
  },
  {
    name: 'Cargos',
    router: 'roles',
    type: 'roles',
    icon: 'briefcase-account-outline'
  },
  {
    name: 'Permissões',
    router: 'permissions',
    type: 'permissions',
    icon: 'account-convert'
  },
  {
    name: 'Auditoria',
    router: 'audits',
    type: 'audits',
    icon: 'text-account'
  },
]
