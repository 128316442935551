import service from './service'

const getAllAudits = async ({ commit }) => {
  const data = await (await service.getAllAudits()).data

  await commit('SAVE_ALL_AUDITS', data)
}

export default {
  getAllAudits,
}
