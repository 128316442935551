import * as types from './mutation-types'
export default {
  [types.SAVE_ALL_FUEL_SUPPLIES](state, data) {
    console.log('mutation ', data)
    state.allFuelSupplies = data
  },
  [types.SAVE_FUEL_SUPPLY](state, data) {
    console.log('mutation ', data)
    state.fuelSupply = data
  },
}
