import service from './service'

const getAllTimeIntervals = async ({ commit }) => {
  const data = await (await service.getAllTimeIntervals()).data

  await commit('SAVE_ALL_TIME_INTERVALS', data)
}

export default {
  getAllTimeIntervals,
}
