import service from './service'

const getAllInfractions = async ({ commit }) => {
  const data = await (await service.getAllInfractions()).data

  await commit('SAVE_ALL_INFRACTIONS', data)
}

export default {
  getAllInfractions,
}
