import service from './service'

const getAllNoteTypes = async ({ commit }) => {
  const data = await (await service.getAllNoteTypes()).data

  console.log('result', data)

  await commit('SAVE_ALL_NOTE_TYPES', data)
}

export default {
  getAllNoteTypes,
}
