import service from './service'

const getAllFuelSupplies = async ({ commit }) => {
  const data = await (await service.getAllFuelSupplies()).data;

  console.log('result', data)

  await commit('SAVE_ALL_FUEL_SUPPLIES', data);
}

export default {
  getAllFuelSupplies,
  // saveEquipment
}
