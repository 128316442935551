import * as types from './mutation-types'
export default {
  [types.SAVE_ALL_MAINTENANCES](state, data) {
    console.log('mutation ', data)
    state.allMaintenances = data
  },
  [types.SAVE_MAINTENANCE](state, data) {
    console.log('mutation ', data)
    state.maintenance = data
  },
}
