import service from './service'

const getAllUnits = async ({ commit }) => {
  const data = await (await service.getAllUnits()).data

  console.log('result', data)

  await commit('SAVE_ALL_UNITS', data)
}

const saveUnit = async ({ commit }, obj: Record<string, string>) => {
  return service.saveUnit(obj).then(async result => {
    await commit('SAVE_UNIT', result.data)
  })
}

export default {
  getAllUnits,
  saveUnit,
}
