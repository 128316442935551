import service from './service'

const getAllIdentifiedProblems = async ({ commit }) => {
  const data = await (await service.getAllIdentifiedProblems()).data

  console.log('result', data)

  await commit('SAVE_ALL_IDENTIFIED_PROBLEMS', data)
}

const saveIdentifiedProblem = async (
  { commit },
  obj: Record<string, string>
) => {
  const data = await (await service.saveIdentifiedProblem(obj)).data

  console.log('result', data)

  await commit('SAVE_IDENTIFIED_PROBLEM', data)
}

export default {
  getAllIdentifiedProblems,
  saveIdentifiedProblem,
}
