import service from './service'

const getAllMaintenanceStatus = async ({ commit }) => {
  const data = await (await service.getAllMaintenanceStatus()).data

  console.log('result', data)

  await commit('SAVE_ALL_MAINTENANCE_STATUS', data)
}

export default {
  getAllMaintenanceStatus,
}
