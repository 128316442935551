import service from './service'

const getAllLocations = async ({ commit }) => {
  const data = await (await service.getAllLocations()).data

  await commit('SAVE_ALL_LOCATIONS', data)
}

export default {
  getAllLocations,
}
