import service from './service'

const getAllBoardingLines = async ({ commit }) => {
  const data = await (await service.getAllBoardingLines()).data

  await commit('SAVE_ALL_BOARDING_LINES', data)
}

export default {
  getAllBoardingLines,
}