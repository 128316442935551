import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllReadjustmentIndices = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/readjustment-indices',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveReadjustmentIndice = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/readjustment-indices',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateReadjustmentIndice = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/readjustment-indices/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteReadjustmentIndice = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/readjustment-indices/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllReadjustmentIndices,
  saveReadjustmentIndice,
  deleteReadjustmentIndice,
  updateReadjustmentIndice,
}
