export default [
  {
    path: '*',
    meta: {
      public: true,
    },
    redirect: {
      path: '/',
    },
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      public: true,
    },
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/',
    redirect: {
      path: '/customers',
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
  },
  {
    path: '/time-intervals',
    name: 'TimeIntervals',
    component: () => import('../views/TimeIntervals.vue'),
  },
  {
    path: '/tolls',
    name: 'Tolls',
    component: () => import('../views/Tolls.vue'),
  },
  {
    path: '/fuel-supplies',
    name: 'FuelSupplies',
    component: () => import('../views/FuelSupplies.vue'),
  },
  {
    path: '/maintenances',
    name: 'Maintenances',
    component: () => import('../views/Maintenances.vue'),
  },
  {
    path: '/part-stocks',
    name: 'PartStocks',
    component: () => import('../views/PartStocks.vue'),
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: () => import('../views/Recipes.vue'),
  },
  {
    path: '/holidays',
    name: 'Holidays',
    component: () => import('../views/Holidays.vue'),
  },
  {
    path: '/logistics',
    name: 'Logistics',
    component: () => import('../views/Logistics.vue'),
  },
  {
    path: '/banks',
    name: 'Banks',
    component: () => import('../views/Banks.vue'),
  },
  {
    path: '/bank-balances',
    name: 'BankBalances',
    component: () => import('../views/BankBalances.vue'),
  },
  {
    path: '/audits',
    name: 'Audits',
    component: () => import('../views/Audits.vue'),
  },
  {
    path: '/expenses',
    name: 'Expenses',
    component: () => import('../views/Expenses.vue'),
  },
  {
    path: '/infractions',
    name: 'infractions',
    component: () => import('../views/Infractions.vue'),
  },
  {
    path: '/financial-services-launch',
    name: 'FinancialServicesLaunch',
    component: () => import('../views/FinancialServiceLaunch.vue'),
  },
  {
    path: '/financial-categories',
    name: 'FinancialCategories',
    component: () => import('../views/FinancialCategories.vue'),
  },
  {
    path: '/financial-revenues',
    name: 'FinancialRevenues',
    component: () => import('../views/FinancialRevenues.vue'),
  },
  {
    path: '/financial-recurrences',
    name: 'FinancialRecurrences',
    component: () => import('../views/FinancialRecurrences.vue'),
  },
  {
    path: '/financial-reports',
    name: 'FinancialReports',
    component: () => import('../views/FinancialReports.vue'),
  },
  {
    path: '/financial-charts',
    name: 'FinancialCharts',
    component: () => import('../views/FinancialCharts.vue'),
  },
  {
    path: '/financial-subcategories',
    name: 'FinancialSubCategories',
    component: () => import('../views/FinancialSubCategories.vue'),
  },
  {
    path: '/locations',
    name: 'Locations',
    component: () => import('../views/Locations.vue'),
  },
  {
    path: '/requesters',
    name: 'Requesters',
    component: () => import('../views/Requesters.vue'),
  },
  {
    path: '/boarding-points',
    name: 'BoardingPoints',
    component: () => import('../views/BoardingPoints.vue'),
  },
  {
    path: '/boarding-lines',
    name: 'BoardingLines',
    component: () => import('../views/BoardingLines.vue'),
  },
  // {
  //   path: '/itineraries',
  //   name: 'Itineraries',
  //   component: () => import('../views/Itineraries.vue'),
  // },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/Customers.vue'),
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: () => import('../views/Drivers.vue'),
  },
  {
    path: '/bills',
    name: 'Bills',
    component: () => import('../views/Bills.vue'),
  },
  {
    path: '/maintenance-schedulings',
    name: 'MaintenanceSchedulings',
    component: () => import('../views/MaintenanceSchedulings.vue'),
  },
  // {
  //   path: '/review-criterias',
  //   name: 'ReviewCriterias',
  //   component: () => import('../views/ReviewCriterias.vue'),
  // },
  // {
  //   path: '/additional-informations',
  //   name: 'AdditionalInformations',
  //   component: () => import('../views/AdditionalInformations.vue'),
  // },
  {
    path: '/vehicle-groups',
    name: 'VehicleGroups',
    component: () => import('../views/VehicleGroups.vue'),
  },
  {
    path: '/additional-users',
    name: 'AdditionalUsers',
    component: () => import('../views/AdditionalUsers.vue'),
  },
  {
    path: '/identified-problems',
    name: 'IdentifiedProblems',
    component: () => import('../views/IdentifiedProblems.vue'),
  },
  {
    path: '/authorized-services',
    name: 'AuthorizedServices',
    component: () => import('../views/AuthorizedServices.vue'),
  },
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/Services.vue'),
  },
  {
    path: '/taxes',
    name: 'Taxes',
    component: () => import('../views/Taxes.vue'),
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('../views/Invoices.vue'),
  },
  {
    path: '/vehicle-types',
    name: 'VehicleTypes',
    component: () => import('../views/VehicleTypes.vue'),
  },
  {
    path: '/vehicle-models',
    name: 'VehicleModels',
    component: () => import('../views/VehicleModels.vue'),
  },
  {
    path: '/vehicle-colors',
    name: 'VehicleColors',
    component: () => import('../views/VehicleColors.vue'),
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: () => import('../views/Vehicles.vue'),
  },
  {
    path: '/passengers',
    name: 'Passengers',
    component: () => import('../views/Passengers.vue'),
  },
  {
    path: '/accessories',
    name: 'Accessories',
    component: () => import('../views/Accessories.vue'),
  },
  {
    path: '/cost-centers',
    name: 'CostCenters',
    component: () => import('../views/CostCenters.vue'),
  },
  {
    path: '/suppliers',
    name: 'Suppliers',
    component: () => import('../views/Suppliers.vue'),
  },
  {
    path: '/roles',
    name: 'Roles',
    component: () => import('../views/Roles.vue'),
  },
  {
    path: '/permissions',
    name: 'Permissions',
    component: () => import('../views/Permissions.vue'),
  },
]
