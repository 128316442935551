import service from './service'

const getAllAdditionalInformations = async ({ commit }) => {
  const data = await (await service.getAllAdditionalInformations()).data

  console.log('result', data)

  await commit('SAVE_ALL_ADDITIONAL_INFORMATIONS', data)
}

const saveAdditionalInformation = async (
  { commit },
  obj: Record<string, string>
) => {
  const data = await (await service.saveAdditionalInformation(obj)).data

  console.log('result', data)

  await commit('SAVE_ACCESSORY', data)
}

export default {
  getAllAdditionalInformations,
  saveAdditionalInformation,
}
