import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllFuelSupplies = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/fuel-supplies',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveFuelSupply = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/fuel-supplies',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveImportFuelSupply = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/fuel-supplies/import',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateFuelSupply = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/fuel-supplies/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteFuelSupply = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/fuel-supplies/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  saveFuelSupply,
  saveImportFuelSupply,
  getAllFuelSupplies,
  updateFuelSupply,
  deleteFuelSupply
}
