import service from './service'

const getAllTolls = async ({ commit }) => {
  const data = await (await service.getAllTolls()).data

  await commit('SAVE_ALL_TOLLS', data)
}

export default {
  getAllTolls,
}
