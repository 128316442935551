import service from './service'

const getAllRoles = async ({ commit }) => {
  const data = await (await service.getAllRoles()).data

  console.log('result', data)

  await commit('SAVE_ALL_ROLES', data)
}

const saveRole = async ({ commit }, obj: Record<string, string>) => {
  return service.saveRole(obj).then(async result => {
    await commit('SAVE_ROLE', result.data)
  })
}

export default {
  getAllRoles,
  saveRole,
}
