import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
    'Content-Type': 'multipart/form-data',
  },
})

const getAllAppraisals = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/appraisals',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveAppraisal = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/appraisals',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateAppraisal = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/appraisals/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteAppraisal = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/appraisals/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllAppraisals,
  saveAppraisal,
  deleteAppraisal,
  updateAppraisal,
}
