import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllUnits = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/federative-units',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveUnit = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/federative-units',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateUnit = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/federative-units/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteUnit = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/federative-units/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllUnits,
  saveUnit,
  deleteUnit,
  updateUnit,
}
