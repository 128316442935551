import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllVehicles = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/vehicles',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveVehicle = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/vehicles',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateVehicle = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/vehicles/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateOdometer = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/vehicles/update-odometer/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteVehicle = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/vehicles/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllVehicles,
  saveVehicle,
  updateOdometer,
  deleteVehicle,
  updateVehicle,
}
