import * as types from './mutation-types'
export default {
  [types.SAVE_ALL_ACCESSORIES](state, data) {
    console.log('mutation ', data)
    state.allAccessory = data
  },
  [types.SAVE_ACCESSORY](state, data) {
    console.log('mutation ', data)
    state.accessory = data
  },
}
