import service from './service'

const getAllVehicleColors = async ({ commit }) => {
  const data = await (await service.getAllVehicleColors()).data

  await commit('SAVE_ALL_VEHICLE_COLORS', data)
}

export default {
  getAllVehicleColors,
}
