import service from './service'

const getAllReadjustmentIndices = async ({ commit }) => {
  const data = await (await service.getAllReadjustmentIndices()).data

  console.log('result', data)

  await commit('SAVE_ALL_READJUSTMENT_INDICES', data)
}

const saveReadjustmentIndice = async (
  { commit },
  obj: Record<string, string>
) => {
  return service.saveReadjustmentIndice(obj).then(async result => {
    await commit('SAVE_READJUSTMENT_INDICE', result.data)
  })
}

export default {
  getAllReadjustmentIndices,
  saveReadjustmentIndice,
}
