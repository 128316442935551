import service from './service'

const getAllMaintenanceTypes = async ({ commit }) => {
  const data = await (await service.getAllMaintenanceTypes()).data

  console.log('result', data)

  await commit('SAVE_ALL_MAINTENANCE_TYPES', data)
}

export default {
  getAllMaintenanceTypes,
}
