import service from './service'

const getAllVehicleTypes = async ({ commit }) => {
  const data = await (await service.getAllVehicleTypes()).data

  console.log('result', data)

  await commit('SAVE_ALL_VEHICLE_TYPES', data)
}

const saveVehicleType = async ({ commit }, obj: Record<string, string>) => {
  const data = await (await service.saveVehicleType(obj)).data

  console.log('result', data)

  await commit('SAVE_VEHICLE_TYPE', data)
}

export default {
  getAllVehicleTypes,
  saveVehicleType,
}
