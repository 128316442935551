import service from './service'

const getAllTaxes = async ({ commit }) => {
  const data = await (await service.getAllTaxes()).data

  await commit('SAVE_ALL_TAXES', data)
}

export default {
  getAllTaxes,
}
