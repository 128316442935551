<template>
  <div>
    <a
      class="text-gray-600 block"
      href="#pablo"
      v-on:click="toggleDropdown($event)"
      ref="btnDropdownRef"
    >
      <div class="items-center flex">
        <span
          class="
            w-12
            h-12
            text-sm text-white
            bg-gray-300
            inline-flex
            items-center
            justify-center
            rounded-full
          "
        >
          <b-icon type="is-info" icon="bell" size="is-medium"></b-icon>
        </span>
      </div>
    </a>
    <div
      ref="popoverDropdownRef"
      class="
        bg-white
        text-base
        z-50
        float-left
        py-2
        list-none
        text-left
        rounded
        shadow-lg
        mt-1
      "
      v-bind:class="{
        hidden: !dropdownPopoverShow,
        block: dropdownPopoverShow,
      }"
      style="
        min-width: 12rem;
        max-width: 300px;
        overflow-y: scroll;
        max-height: 300px;
      "
    >
      <div v-if="allFilterNotifications.length > 0" style="overflow-y: scroll">
        <a
          v-for="(item, index) in allFilterNotifications"
          :key="index"
          :href="item.type === 'bill' ? '/bills' : '/services'"
          class="
            text-sm
            py-2
            px-4
            font-normal
            block
            w-full
            bg-transparent
            text-gray-800
          "
        >
          {{ item.type === 'bill' ? 'Conta (' + item.name + ')' : 'Serviço' }}
          <br />
          <span v-if="item.type === 'services'">{{ item.text }}</span>
          <b-taglist v-if="item.type === 'bill'" style="margin-top: 3px">
            <b-tag :type="item.days_ago ? 'is-danger' : 'is-warning'">{{
              item.days_ago
                ? 'Atrasada em ' + item.days_ago + ' dia(s)'
                : 'Pendente'
            }}</b-tag>
          </b-taglist>
        </a>
      </div>
      <div v-else>
        <a
          class="
            text-sm
            py-2
            px-4
            font-normal
            block
            w-full
            whitespace-no-wrap
            bg-transparent
            text-gray-800
          "
        >
          Não há nenhuma notificação nesta área
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Popper from 'popper.js'
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      dropdownPopoverShow: false,
      allFilterNotifications: [],
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    ...mapState('notifications', ['allNotifications']),
  },
  sockets: {
    connect: function () {
      console.log('socket connected')
    },
    customEmit: function (data) {
      console.log(
        'this method was fired by the socket server. eg: io.emit("customEmit", data)'
      )
    },
  },
  methods: {
    ...mapActions('notifications', ['getAllNotifications']),
    async init() {
      await this.getAllNotifications()

      // this.sockets.subscribe('service-change-notification', (data) => {
      //   this.init()
      // })

      if (
        this.$route.name.toLowerCase() !== 'services' &&
        this.$route.name.toLowerCase() !== 'logistics'
      ) {
        this.allFilterNotifications = this.allNotifications.filter(
          (x) => x.type !== 'services'
        )
      } else {
        this.allFilterNotifications = this.allNotifications.filter(
          (x) => x.type === 'services'
        )
      }
    },
    toggleDropdown: function (event) {
      event.preventDefault()
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false
      } else {
        this.dropdownPopoverShow = true
        new Popper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: 'bottom-end',
        })
      }
    },
  },
}
</script>
