import * as types from './mutation-types'
export default {
  [types.SAVE_ALL_PERMISSIONS](state, data) {
    console.log('mutation ', data)
    state.allPermissions = data
  },
  [types.SAVE_PERMISSION](state, data) {
    console.log('mutation ', data)
    state.permission = data
  },
}
