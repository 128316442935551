import service from './service'

const getAllVehicleMovementRecords = async ({ commit }) => {
  const data = await (await service.getAllVehicleMovementRecords()).data

  console.log('result', data)

  await commit('SAVE_ALL_VEHICLE_MOVEMENT_RECORDS', data)
}

const saveVehicleMovementRecord = async (
  { commit },
  obj: Record<string, string>
) => {
  return service.saveVehicleMovementRecord(obj).then(async result => {
    await commit('SAVE_VEHICLE_MOVEMENT_RECORD', result.data)
  })
}

export default {
  getAllVehicleMovementRecords,
  saveVehicleMovementRecord,
}
