import service from './service'

const getAllRecipes = async ({ commit }) => {
  const data = await (await service.getAllRecipes()).data

  await commit('SAVE_ALL_RECIPES', data)
}

export default {
  getAllRecipes,
}