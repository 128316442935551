import service from './service'

const getAllVehicleUsageInfos = async ({ commit }) => {
  const data = await (await service.getAllVehicleUsageInfos()).data

  console.log('result', data)

  await commit('SAVE_ALL_VEHICLE_USAGE_INFOS', data)
}

const saveVehicleUsageInfo = async (
  { commit },
  obj: Record<string, string>
) => {
  const data = await (await service.saveVehicleUsageInfo(obj)).data

  console.log('result', data)

  await commit('SAVE_VEHICLE_USAGE_INFO', data)
}

export default {
  getAllVehicleUsageInfos,
  saveVehicleUsageInfo,
}
