import service from './service'

const getAllPendingReleases = async ({ commit }) => {
  const data = await (await service.getAllPendingReleases()).data

  await commit('SAVE_ALL_PENDING_RELEASES', data)
}

export default {
  getAllPendingReleases,
}
