import service from './service'

const getAllAppraisals = async ({ commit }) => {
  const data = await (await service.getAllAppraisals()).data

  console.log('result', data)

  await commit('SAVE_ALL_APPRAISALS', data)
}

export default {
  getAllAppraisals,
}
