import service from './service'

const getAllItineraries = async ({ commit }) => {
  const data = await (await service.getAllItineraries()).data

  await commit('SAVE_ALL_ITINERARIES', data)
}

export default {
  getAllItineraries,
}
