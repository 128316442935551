import service from './service'

const getAllBanks = async ({ commit }) => {
  const data = await (await service.getAllBanks()).data

  await commit('SAVE_ALL_BANKS', data)
}

export default {
  getAllBanks,
}
