import service from './service'

const getAllBankBalances = async ({ commit }) => {
  const data = await (await service.getAllBankBalances()).data

  await commit('SAVE_ALL_BANK_BALANCES', data)
}

export default {
  getAllBankBalances,
}
