import * as types from './mutation-types'
export default {
  [types.SAVE_ALL_ADDITIONAL_INFORMATIONS](state, data) {
    console.log('mutation ', data)
    state.allAdditionalInformations = data
  },
  [types.SAVE_ADDITIONAL_INFORMATION](state, data) {
    console.log('mutation ', data)
    state.additionalInformation = data
  },
}
