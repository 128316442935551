import service from './service'

const getAllCustomers = async ({ commit }) => {
  const data = await (await service.getAllCustomers()).data

  await commit('SAVE_ALL_CUSTOMERS', data)
}

export default {
  getAllCustomers,
}
