var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full w-full",attrs:{"id":"app"}},[(!_vm.$route.meta.public)?_c('div',[_c('sidebar-component'),_c('div',{staticClass:"relative h-full",class:{
      'md:ml-64':
        _vm.$route.name.toLowerCase() !== 'services' &&
        _vm.$route.name.toLowerCase() !== 'logistics' && !_vm.isToggleMenuActive,
      'margin-toggle-menu': _vm.isToggleMenuActive,
    }},[_c('navbar-component'),_c('div',{staticClass:"relative bg-gray-800",class:{
        'pb-32':
          _vm.$route.name.toLowerCase() !== 'services' &&
          _vm.$route.name.toLowerCase() !== 'logistics',
        'pt-12':
          _vm.$route.name.toLowerCase() !== 'services' &&
          _vm.$route.name.toLowerCase() !== 'logistics',
        'pt-32':
          _vm.$route.name.toLowerCase() === 'services' ||
          _vm.$route.name.toLowerCase() === 'logistics',
      }},[_c('div',{staticClass:"px-4 md:px-10 mx-auto w-full"})]),_c('div',{staticClass:"px-4 py-10 md:px-10 mx-auto w-full bg-gray-200 -m-32 z-10"},[_c('div',{staticClass:"pb-10"},[_c('router-view')],1),(!_vm.$route.meta.public &&
          _vm.$route.name.toLowerCase() !== 'services' &&
          _vm.$route.name.toLowerCase() !== 'logistics'
          )?_c('footer',{staticClass:"bg-gray-200 bottom-0 z-10 fixed w-full text-left p-4",class:_vm.collapse ? 'hidden' : 'block'},[_vm._v(" © Onix Autolocadora 2020. Todos os direitos reservados. "),_c('br')]):_vm._e()])],1)],1):_vm._e(),(_vm.$route.meta.public)?_c('div',[_c('router-view')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }