import Axios from 'axios'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_API_URL}/api`,
    'access-control-allow-headers': 'Authorization',
  },
})

const getAllLocations = async () => {
  return axios.request({
    method: 'GET',
    url: '/api/locations',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveImportCustomerData = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/locations/import-data-customer',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveImportDriverData = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/locations/import-data-driver',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const saveLocation = async (data: Record<string, string>) => {
  return axios.request({
    method: 'POST',
    url: '/api/locations',
    data,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const updateLocation = async ({ id, ...data }) => {
  return axios.request({
    method: 'patch',
    url: `/api/locations/${id}`,
    data: { id: id, ...data },
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

const deleteLocation = async id => {
  return axios.request({
    method: 'DELETE',
    url: `/api/locations/${id}`,
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  })
}

export default {
  getAllLocations,
  saveLocation,
  deleteLocation,
  updateLocation,
  saveImportCustomerData,
  saveImportDriverData
}
