const SwalUtils = {
  install(Vue) {
    Vue.prototype.$error = function(err) {
      this.$swal({
        icon: 'error',
        title: 'Oops...',
        text: err,
      })
    }
    Vue.prototype.$success = function(value) {
      return this.$swal.fire({
        position: 'center',
        icon: 'success',
        html: `<b>${value}</b> salvo com sucesso!`,
        showConfirmButton: false,
        timer: 1500,
      })
    }
    Vue.prototype.$ask = function(value) {
      return this.$swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Aviso',
        text: value,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `Continuar`,
        cancelButtonText: `Cancelar`,
      })
    }
    Vue.prototype.$delete = function(value) {
      return this.$swal.fire({
        position: 'center',
        icon: 'success',
        html: `<b>${value}</b> deletado com sucesso!`,
        showConfirmButton: false,
        timer: 1500,
      })
    }
    Vue.prototype.$cancel = function(value) {
      return this.$swal.fire({
        position: 'center',
        icon: 'success',
        html: `<b>${value}</b> cancelado com sucesso!`,
        showConfirmButton: false,
        timer: 1500,
      })
    }
    Vue.prototype.$toastInfo = function(value, color) {
      return this.$swal.fire({
        position: 'top-end',
        background: color,
        toast: true,
        html: `<p style="color: white;">${value}</p>`,
        timer: 3000,
        showConfirmButton: false
      })
    }
  },
}

export default SwalUtils
