import service from './service'

const getAllServiceObservations = async ({ commit }) => {
  const data = await (await service.getAllServiceObservations()).data

  await commit('SAVE_ALL_SERVICE_OBSERVATIONS', data)
}

export default {
  getAllServiceObservations,
}
