import service from './service'

const getAllDrivers = async ({ commit }) => {
  const data = await (await service.getAllDrivers()).data

  await commit('SAVE_ALL_DRIVERS', data)
}

export default {
  getAllDrivers,
}
