import service from './service'

const getAllVehicles = async ({ commit }) => {
  const data = await (await service.getAllVehicles()).data

  console.log('result', data)

  await commit('SAVE_ALL_VEHICLES', data)
}

const saveVehicle = async ({ commit }, obj: Record<string, string>) => {
  const data = await (await service.saveVehicle(obj)).data

  console.log('result', data)

  await commit('SAVE_VEHICLE', data)
}

export default {
  getAllVehicles,
  saveVehicle,
}
