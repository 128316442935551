import service from './service'

const getAllAuthorizedServices = async ({ commit }) => {
  const data = await (await service.getAllAuthorizedServices()).data

  console.log('result', data)

  await commit('SAVE_ALL_AUTHORIZED_SERVICES', data)
}

const saveAuthorizedService = async (
  { commit },
  obj: Record<string, string>
) => {
  const data = await (await service.saveAuthorizedService(obj)).data

  console.log('result', data)

  await commit('SAVE_AUTHORIZED_SERVICE', data)
}

export default {
  getAllAuthorizedServices,
  saveAuthorizedService,
}
