import service from './service'

const getAllBoardingPoints = async ({ commit }) => {
  const data = await (await service.getAllBoardingPoints()).data

  await commit('SAVE_ALL_BOARDING_POINTS', data)
}

export default {
  getAllBoardingPoints,
}