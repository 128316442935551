import Vue from 'vue'
import App from './App.vue'
import moment from 'moment'
import './registerServiceWorker'
import router from './router'
import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserSecret,
  faChartBar,
  faTruckMoving,
  faUsers,
  faWrench,
  faParachuteBox,
  faBriefcase,
  faClipboardList,
  faUsersCog,
  faCoins,
  faTimesCircle,
  faBars,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTheMask from 'vue-the-mask'
import vMultiselectListbox from 'vue-multiselect-listbox'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

import VueQrcodeReader from 'vue-qrcode-reader'

import { Can } from '@casl/vue'
import { abilitiesPlugin } from '@casl/vue'

import './assets/styles/index.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue-js-modal/dist/styles.css'
import 'vue-search-select/dist/VueSearchSelect.css'

import VueMask from 'v-mask'
import Multiselect from 'vue-multiselect'
import { Ability } from '@casl/ability'
import VueCurrencyInput from 'vue-currency-input'
import SwalUtils from './utils/swal.js'
import Formatters from './utils/formatters.js'
import Validators from './utils/validators'
import Cleave from 'vue-cleave-component'
import JsonExcel from 'vue-json-excel'
import money from 'v-money'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import VueSocketIO from 'vue-socket.io';
import { ModelListSelect } from 'vue-search-select'

Vue.component('multiselect', Multiselect)
Vue.component('model-list-select', ModelListSelect)

library.add(
  faUserSecret,
  faChartBar,
  faTruckMoving,
  faUsers,
  faWrench,
  faParachuteBox,
  faBriefcase,
  faClipboardList,
  faUsersCog,
  faCoins,
  faTimesCircle,
  faBars
)

const ability = new Ability([])

// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: 'https://socket.onixautolocadora.com/', //options object is Optional
//   vuex: {
//     store,
//     actionPrefix: "SOCKET_",
//     mutationPrefix: "SOCKET_"
//   }
// })
// );


Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueQrcodeReader)
Vue.use(VueSweetalert2)
Vue.use(abilitiesPlugin, ability)
Vue.use(VueCurrencyInput)
Vue.use(SwalUtils)
Vue.use(Formatters)
Vue.use(Validators)
Vue.use(Cleave)
Vue.use(Buefy)
Vue.use(money, { precision: 2 })

Vue.prototype.$moment = value => {
  moment.locale('pt')
  if (!value) return moment()
  return moment(value)
}

Vue.use(VueTheMask)

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('multiselect', Multiselect)
Vue.component('v-date-picker', DatePicker)
Vue.component('Can', Can)
Vue.component('v-multiselect-listbox', vMultiselectListbox)
Vue.component('download-excel', JsonExcel)

Vue.use(VueMask, {
  placeholders: {
    '#': null, // passing `null` removes default placeholder, so `#` is treated as character
    D: /\d/, // define new placeholder
    Я: /[\wа-яА-Я]/, // cyrillic letter as a placeholder
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
