import * as types from './mutation-types'
export default {
  [types.SAVE_ALL_VEHICLE_TYPES](state, data) {
    console.log('mutation ', data)
    state.allVehicleTypes = data
  },
  [types.SAVE_VEHICLE_TYPE](state, data) {
    console.log('mutation ', data)
    state.vehicleType = data
  },
}
