import service from './service'

const getAllVehicleGroups = async ({ commit }) => {
  const data = await (await service.getAllVehicleGroups()).data

  console.log('result', data)

  await commit('SAVE_ALL_VEHICLE_GROUPS', data)
}

const saveVehicleGroup = async ({ commit }, obj: Record<string, string>) => {
  return service.saveVehicleGroup(obj).then(async result => {
    await commit('SAVE_VEHICLE_GROUP', result.data)
  })
}

export default {
  getAllVehicleGroups,
  saveVehicleGroup,
}
